<template>
  <nav class="nav">
    <router-link 
      @click="toggleMenu"
      to="/"
      class="text-xl rounded text-blue-600 font-semibold mb-4 capitalize flex items-center cursor-pointer"
    ><i class="ri-arrow-go-back-line icon mr-2"></i> Início
    </router-link>
    <!-- <router-link @click="toggleMenu" to="/ajuda" class="help">
      <i class="ri-question-line animate-bounce"></i>
      <span>Como posso ajudar?</span>
    </router-link> -->
    <section>
      <LinkModal @click="toggleMenu" :data='dcc'></LinkModal>
      <LinkModal @click="toggleMenu" class="border-t-2" :data='pmdf'></LinkModal>
    </section>
  </nav>
</template>

<script>
import LinkModal from './menuModal/link-modal.vue';

export default {
  name: 'MenuModal',
  components: {
    LinkModal,
  },
  setup(_, context) {
    const dcc = {
      title: 'Serviços',
      data: [
        {
          label: 'Certidão Negativa > Emitir',
          icon: 'ri-shield-check-line text-blue-600 hover:-translate-y-2',
          src: 'https://sgc.dcc.pm.df.gov.br/nadaconsta/emitirCertidao.faces',
        },
        {
          label: 'Certidão Negativa > Pesquisar/Validar',
          icon: 'ri-file-search-line text-blue-600 hover:-translate-y-2',
          src: 'https://sgc.dcc.pm.df.gov.br/nadaconsta/validarCertidao.faces',
        },
        {
          label: 'SGC',
          icon: 'ri-scales-3-line text-blue-600 hover:-translate-y-2',
          src: 'https://sgc.dcc.pm.df.gov.br/',
        },
        {
          label: 'Recuperar Senha',
          icon: 'ri-key-2-line text-blue-600 hover:-translate-y-2',
          path: '/email',
        },
        // {
        //   label: 'Termo de Compromisso',
        //   icon: 'ri-draft-line text-blue-600 hover:-translate-y-2',
        //   path: '/termo',
        // },
        {
          label: 'Suporte Técnico - SGC',
          icon: 'ri-customer-service-2-line text-blue-600 hover:-translate-y-2',
          src: 'https://suporte.dcc.pm.df.gov.br/',
        },
      ],
    };
    const pmdf = {
      title: 'Links',
      data: [
        {
          label: 'SEI',
          icon: 'ri-folder-4-line text-green-600 hover:-translate-y-2',
          src: 'https://sei.df.gov.br/sip/login.php?sigla_orgao_sistema=GDF&sigla_sistema=SEI',
        },
        {
          label: 'PMDF',
          icon: 'ri-shield-line text-green-600 hover:-translate-y-2',
          src: 'http://www.pmdf.df.gov.br/',
        },
        {
          label: 'Intranet',
          icon: 'ri-lock-password-line text-green-600 hover:-translate-y-2',
          src: 'https://intranet.pmdf.df.gov.br/portal/',
        },
        {
          label: 'SIPOM',
          icon: 'ri-spy-line text-green-600 hover:-translate-y-2',
          src: 'https://www.sipom.pm.df.gov.br/intranet',
        },
        {
          label: 'SGPol',
          icon: 'ri-team-line text-green-600 hover:-translate-y-2',
          src: 'https://sgpol.pm.df.gov.br/login',
        },
        {
          label: 'Gênesis',
          icon: 'ri-global-line text-green-600 hover:rotate-90',
          src: 'https://genesis.pm.df.gov.br/login.aspx?ReturnUrl=%2fdefault.aspx',
        },
        {
          label: 'Marcação de Consulta',
          icon: 'ri-stethoscope-line text-green-600 hover:-translate-y-2',
          src: 'http://marcacao.saude.pm.df.gov.br/marcacaoweb/login/login',
        },
        {
          label: 'SVG',
          icon: 'ri-user-follow-line text-green-600 hover:-translate-y-2',
          src: 'http://svg.pm.df.gov.br/SVG/',
        },
        {
          label: 'EAD DEC',
          icon: 'ri-book-mark-line text-green-600 hover:-translate-y-2',
          src: 'https://virtual.iscp.edu.br/auth_custom/',
        }
      ],
    };
    function toggleMenu() {
      context.emit('close', false);
    }
    return {
      dcc,
      pmdf,
      toggleMenu,
    };
  },
};

</script>

<style scoped>
.nav {
  @apply z-40 p-4 max-w-xs w-full bg-blue-50 rounded shadow-md;
}
.help {
  @apply bg-white col-span-3 cursor-pointer flex justify-center items-center gap-2 p-4 mb-4 text-xl text-blue-400;
}
</style>